import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import image1 from "../../../assets/images/blog/drupal10/image1.png"
import image2 from "../../../assets/images/blog/drupal10/image2.png"
import image3 from "../../../assets/images/blog/drupal10/image3.png"
import image4 from "../../../assets/images/blog/drupal10/image4.png"
import image5 from "../../../assets/images/blog/drupal10/image5.png"

export default () => (
  <div>
    <SEO
      title={"Drupal vs. Magnolia"}
      description="Drupal vs. Magnolia"
      canonical={"https://icon-worldwide.com/blog/drupal10"}
      image={"https://icon-worldwide.com/social_images/drupal10.png"}
    />

    <NewsHeader />

    <div id="single-news">
      <h1>
        Ready for <span>Drupal 10</span>? Check Your Sites Readiness for{" "}
        <span>8 New Features</span>.
      </h1>
      <div id="title-underline"></div>
      <div id="single-news-texts">
        <img
          src={image1}
          alt="Ready for Drupal 10? Check Your Sites Readiness for 8 New Features."
          title="Ready for Drupal 10? Check Your Sites Readiness for 8 New Features."
          style={{ width: "100%", display: "block", margin: "0 0 30px" }}
        />
        <h2>Drupal 10 is scheduled for release in December, 2022.</h2>
        <p>
          In December, 2022 Drupal will have another major release. As Drupal
          developers maintaining platforms for many organizations, big and
          small, we know any major Drupal release is Good News that brings
          effort and costs. We’ll try to explain what's behind this release, why
          you can’t delay, and how it will improve your business.
        </p>

        <p>
          Drupal 10 comes with many improved and new features. They are mainly
          aimed at modernizing the platform. We all like new features, but more
          critically, Drupal 10 updates many of the foundation technologies of
          Drupal.
        </p>

        <p>
          As an open-source, community led technology Drupal is highly dependent
          on a large number of third-party components. As these components
          mature, evolve and eventually reach end-of-life, Drupal has to evolve
          to support the latest versions.
        </p>

        <p>
          Coordinating these efforts is no easy challenge for the Drupal
          community. Clients are always asking us how to plan for updates and
          maintenance. While some guidance is possible, the roadmap is often
          delayed, due to slower developments, or alarmingly sped up as security
          demands arise.
        </p>

        <h2>How to Prepare for Drupal 10: Drupal Readiness</h2>

        <p>
          The first step is aligning the updates required for the non-Drupal
          components that Drupal relies on, some of which are reaching end of
          life. Drupal core is heavily dependent on components such as Symphony,
          CKEditor and, most importantly, PHP.
        </p>
        <img
          src={image2}
          alt="How to Prepare for Drupal 10: Drupal Readiness"
          title="How to Prepare for Drupal 10: Drupal Readiness"
          style={{ width: "100%", display: "block", margin: "0 0 30px" }}
        />

        <h2>PHP 7 & Symfony 4: End of Life, November 2022</h2>

        <p>
          Drupal relies heavily on the Symfony framework to provide a lot of
          basic functionality such as managing cookies, handling and routing
          incoming requests, and services. Drupal 9 uses Symfony version 4,
          which is reaching end of life and support by November 2022. The Drupal
          10 upgrade will be skipping a version of Symfony going directly to
          Symfony 6.2
        </p>

        <p>
          <strong>
            Bottom-line: Drupal 9 websites will be running on an unsupported,
            not secure, version of Symfony after November, 2022.
          </strong>
        </p>
        <p>
          Drupal 10 increases the PHP version to PHP 8.1. This requirement stems
          from Symfony 6.2 requiring PHP 8.1. Most Drupal 9 websites are built
          with PHP7. Support for the latest version, PHP7.4 will end in
          November, 2022. The current version, PHP 8.1 is not compatible with
          Drupal 9.
        </p>
        <p>
          <strong>
            Bottom-line: PHP 8.1 is the first critical step in the update to
            Drupal 10. For many websites the update to PHP 8.1 will actually be
            the largest effort and risk involved in the Drupal 10 update.
          </strong>
        </p>

        <img
          src={image3}
          alt="PHP 7 & Symfony 4: End of Life, November 2022"
          title="PHP 7 & Symfony 4: End of Life, November 2022"
          style={{ width: "60%", display: "block", margin: "0 0 30px" }}
        />

        <h2>
          Drupal 10 Removes a Lot of Deprecated Code: Proceed with Caution
        </h2>

        <p>
          While some steps to the Drupal 10 update may be fairly simple for most
          developers, some care is required. Drupal core code and libraries that
          have been identified as "deprecated" in Drupal 9 will be removed.
          However, this deprecated code can't be immediately removed as it may
          break functionality in a site's custom code or contributed modules.
          Therefore, developers and Q/A need to work carefully to cross-check
          the impact of deprecated code on each page and functionality.
        </p>

        <h2>The Good News: Awesome New Features We Expect in Drupal 10:</h2>

        <h3>A more modern front-end theme: Olivero</h3>

        <p>
          Users complained that Bartik, the default front-end theme for Drupal,
          is obsolete. They felt the 11-year-old theme had an outdated design
          and wasn’t compatible with some of the newest features of the
          software.
        </p>

        <p>They will be happy to hear that their calls have been answered.</p>

        <p>
          Drupal 10 will have a new default front-end theme. It’s called
          Oliveiro. It has a clean, modern look, it’s easy to navigate and works
          well with features the users appreciate.
        </p>

        <h3>Improved Accessibility</h3>

        <p>
          Crucially, Drupal 10 improves compliance with the Web Content
          Accessibility Guidelines (WCAG) which is a set of recommendations to
          make online content more accessible to internet users with
          disabilities such as blindness, low vision, deafness, hearing loss,
          limited movements and more. Its conformance with the recommendations
          will have an AA level, which means mid range. The system needed to
          meet 38 criteria to get it which shows an admirable effort.
        </p>
        <h3>Updated administration theme: Claro</h3>

        <p>
          The administration theme Seven is by now also quite old. It was
          designed 13 years ago and some claim that at this point it even
          discourages users with its retro looks.
        </p>

        <p>Drupal addresses this with Claro.</p>

        <p>
          Just like Oliveiro it is a clean, modern looking theme, designed
          according to the WCAG recommendations. It will be able to take full
          advantage of the capabilities of Drupal 10 and commentators expect it
          to make life easier for content managers and site administrators.
        </p>

        <img
          src={image4}
          alt="Drupal addresses this with Claro."
          title="Drupal addresses this with Claro."
          style={{ width: "100%", display: "block", margin: "0 0 30px" }}
        />

        <h3>Automatic Updates Will Make Your Website More Secure</h3>

        <p>
          Drupal 10 will make life for developers much easier. This will be
          thanks to its planned automatic updates feature. It will not be
          available for all websites but still it’s a big step forward.
        </p>

        <p>
          This function will include only security and core updates for now but
          it’s said it will be expanded in the future and it’s not an
          insignificant option. Drupal takes security very seriously and works a
          lot on amending vulnerabilities once they are discovered. The problem
          is that at the moment website owners need to apply these updates
          manually and they rarely do. Automation takes important care of this.
        </p>

        <h3>The Project Browser: Another Change Towards Autonomy</h3>

        <p>
          This is another change with which Drupal 10 helps non-developers and
          new users become more autonomous.
        </p>

        <p>
          The way the system works is the following - you have a core theme on
          which you put different modules. The problem is finding the ones you
          need can be really difficult if you are not a skilled professional.
          There’s a lot to take into account from compatibility to whether the
          module you want to add even exists.
        </p>

        <p>
          Drupal 10 will introduce a module browsing experience which will help
          users filter through all options and find the one suitable for them.
          If they find it difficult to choose, a list of most-liked will help
          them narrow their selection.
        </p>

        <h3>An Improved Text Editor: CKEditor 5</h3>

        <p>
          CKEditor is a text editor which allows for writing content directly
          into a webpage. Until now Drupal used its previous incarnation -
          CKEditor 4. The new version comes with a better interface and user
          experience.
        </p>

        <p>
          Its design will be reminiscent of popular text editing programs like
          MS Word or Google Docs. It will be more efficient than its
          predecessor. It will automate formatting options, improve the paste
          function and will have enhanced image-upload functionalities. SEO
          specialists will also be thankful for the removal of the option of
          adding an H1 tag in the text which confuses search engines and doesn’t
          help your optimization efforts.
        </p>

        <img
          src={image5}
          alt="An Improved Text Editor: CKEditor 5"
          title="An Improved Text Editor: CKEditor 5"
          style={{ width: "100%", display: "block", margin: "0 0 30px" }}
        />

        <h3>Some Things Will Be Removed </h3>

        <p>
          Alongside all the newly added features, Drupal 10 will also have
          others removed. There will be a clean-up of its not so popular
          modules, JavaScript dependencies and deprecated code.
        </p>

        <p>Let’s discuss the modules first.</p>

        <p>
          Statistics show that some of the modules on Drupal are not widely used
          by people taking advantage of the platform. That’s why now they will
          be removed to declutter it visually and to improve experience. It’s
          not yet clear exactly which modules are to be removed but it’s rumored
          that the list will include Aggregator (for external content),
          QuickEdit (for text editing), HAL (for serializing entities), Activity
          Tracker (allows tracking of content), RDF (adds metadata to a page),
          Forum (for discussion boards).
        </p>

        <p>JavaScript dependencies will also be removed.</p>

        <p>
          There will be a couple of changes but the one that is most widely
          discussed is the elimination of some uses of jQuery because this
          software that simplifies the use of JavaScript has been part of Drupal
          since 2007. However, security concerns lead to its partial deletion.
        </p>

        <p>
          Another change will be the removal of older codes that are no longer
          useful.
        </p>

        <p>
          Also, users will not be able to use Drupal 10 on Internet Explorer 11,
          the browser version, released in 2013.
        </p>

        <p>
          Symfony 4, a safe space for developers to make changes in before
          updating the live version of a website, won’t be supported as of
          November 2022. Drupal 10 will work with its newest version Symfony 6
          which will have many modern features.
        </p>

        <h2>Conclusion</h2>
        <p>
          The new update of Drupal to version 10 makes a much appreciated
          content management system even better.
        </p>

        <p>
          It will improve the user experience by providing an updated interface
          with a new, clean, modern, easier to navigate look. This positive
          direction extends to decluttering of less used modules and the
          addition of a better text editor which is similar to popular platforms
          in the sphere, to which content creators are used.
        </p>

        <p>
          Automatic updates will make your website safer and new features will
          help you become more autonomous while using Drupal.
        </p>
      </div>

      <img
        src={arrow}
        id="single-news-horizontal-arrow"
        alt="ICON Worldwide arrow"
        title="ICON Worldwide arrow"
      />
      <h4>
        <Link to="/contact-us" className="cta">
          CONTACT US
        </Link>
      </h4>

      <NewsFooter
        previous="icon-open-ai-chat"
        next="crypto-casino"
      />
    </div>

    <Footer noScrollbar="true" />
  </div>
)
